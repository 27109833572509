import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, FormGroup, Form } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicLayout from "../components/PublicLayout/publicLayout";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import { getCookies } from "../utils/Helper";

const ValidateOTP = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState({});
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes timer
  const [isExpired, setIsExpired] = useState(false);
  const { setAccessToken } = useContext(UserContext);
  const { email } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };
  const [isLoading, setIsLoading] = useState(false);
  let currentEmail = getCookies("forgotEmail");
  let visiblePart = currentEmail.substring(0, 4) + "********";

  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    setIsComplete(newOtp.every((digit) => digit.length === 1));

    if (value.length === 1 && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
    if (value === "" && index > 0 && e.nativeEvent.inputType === "deleteContentBackward") {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  useEffect(() => {
    if (timeLeft > 0 && !isExpired) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setIsExpired(true);
      showToast("OTP expired! Please request a new one.", "error");
    }
  }, [timeLeft, isExpired]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    if (timeLeft > 0) return; // Prevent resend if timer is active
    setIsExpired(false);
    setTimeLeft(300); // Reset timer
    let emailAddress = getCookies("forgotEmail");
    setLoad(true);
    if (validate()) {
      const res = await APIServices.post(
        "forgotPassword",
        { emailAddress },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        showToast(res?.data?.message || "Success", "success");
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  const validate = () => {
    const errors = {};
    if (!otp) {
      errors.otp = "OTP is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    let email = getCookies("forgotEmail");
    e.preventDefault();
    setLoad(true);
    if (validate()) {
      const res = await APIServices.post(
        "verifyOTP",
        { email, otp },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/reset-password");
        }, 2000);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  return (
    <PublicLayout>
      <section className="login_page_inner oborna_login_sec">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="login-form">
                <div className="text-center top">
                  <img
                    src="/images/oborna.svg"
                    className="img-fluid mb-5"
                    alt="logo"
                  />
                  <h1 className="login-heading text-center font_24 font_700 mb-3">
                    OTP Verification
                  </h1>
                  <p className="login-subheading text-center font_12 font_700 mb-3">
                    Please enter the code that has been sent <br /> to you at
                    email : {visiblePart}
                  </p>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mt-3 mb-3">
                    <div className="verification-code">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          type="text"
                          className={`form-control ${focusedIndex === index ? "active" : ""}`}
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onFocus={() => setFocusedIndex(index)}
                          onBlur={() => setFocusedIndex(null)}
                          autoComplete="off"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          disabled={isExpired}
                        />
                      ))}
                    </div>
                  </FormGroup>
                  <p className="login-subheading text-center font_12">
                    Code expires in: <span className="login-subheading text-center font_12 font_700 mb-3">{formatTime(timeLeft)}</span>
                  </p><br />
                  {isExpired ? (
                    <p className="login-subheading text-center font_12 font_700 mb-3 text-danger">
                      OTP Expired!{" "}
                      <span
                        onClick={handleResendOTP}
                        className="resend-code"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Resend Code
                      </span>
                    </p>
                  ) : (
                    <p className="login-subheading text-center font_12">
                      Don't Receive Your Code?{" "}
                      <span
                        onClick={handleResendOTP}
                        className={`resend-code ${timeLeft > 0 ? 'disabled-btn' : ''}`}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Resend Code
                      </span>
                    </p>
                  )}
                  <div className="text-center d-flex align-items-center otp_btn">
                    <Link className="font_12 font_700 d-block w-50" to="/forgot-password">
                      <Button className="btn sent_btn font_black_14 w-100 py-2 font_700" type="button">
                        Back
                      </Button>
                    </Link>
                    <Button
                      className={`btn default-btn py-2 my-4 font_700 w-50 bg-transprint ${isComplete ? "btn-active" : "btn-inactive"}`}
                      type="submit"
                      disabled={isExpired || !isComplete}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="login-right-image grey_box row_col_full">
                <img src="/images/login_sideimage.svg" className="img-fluid login_sideimage" alt="login image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </PublicLayout>
  );
};

export default ValidateOTP;
