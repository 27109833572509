import React, { useEffect, useState } from "react";
import { getCookies, setCookies } from "../utils/Helper";
import APIServicenew from "../utils/APIGeneralService";

//user context file to stroge login infor of users
const UserContext = React.createContext();

const UserContextProvider = (props) => {
  const [accessToken, setAccessToken] = useState(getCookies("accessToken"));
  const [data, setData] = useState();
  const [email,setEmail] = useState();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const [permissionscontext, setPermissionscontext] = useState([]);

  const headers = { "auth-token": authToken, authorization: accessToken };

  //logout function 
  const logout = (e) => {
    //reset or null cookies and state
    setAccessToken(null);
    setCookies("accessToken", null);
    setCookies("name", null);
    
    // localStorage.removeItem('accessToken');
  };

  //fetch user profile data
  const fetchData = async () => {
    if (accessToken) {
      try {
        //call get profile API
        const res = await APIServices.get("getProfile", headers);
        if (res?.data?.status === true) {
          setData(res?.data?.detail);
          setCookies("name", res?.data?.detail?.first_name);
        } else {
          console.error(res?.data?.message);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{ accessToken, setAccessToken, logout, data, fetchData,setPermissionscontext,permissionscontext,email,setEmail }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext };
