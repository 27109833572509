import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { capitalizeFirstLetter } from "../../utils/Helper";
import moment from "moment";

export default function DeleteModal({ show, handleClose, groupData }) {
  return (
    <>
      <Modal
        isOpen={show}
        size="xl"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleClose}
        gradient="danger"
        className="modal-dialog-centered"
      >
        <ModalHeader
          toggle={handleClose}
          className="modal-header-hd justify-content-between"
        >
          Group Message [{groupData[0]?.type}]
        </ModalHeader>
        <ModalBody>
          {/* Bootstrap-styled Table */}
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Contact Name</th>
                <th>{groupData[0]?.type == "SMS" ? "Phone Number" : "Address"}</th>
                <th>Schedule Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {groupData && groupData?.length > 0 && groupData.map((row) => (
                <tr key={row.mobile_number}>
                  <td>{capitalizeFirstLetter(row.name)}</td>
                  {row.type == "SMS" ? (
                    <td>{row.mobile_number ? "(" + row.country_code + ") " + row.mobile_number : "-"}</td>
                  ) : (
                    <td>{row.address ? row.address + ", " + row.city + ", " + row.zipcode + ", " + row.country : "-"}</td>
                  )
                  }
                  <td>{moment(row.schedule_date).format("MMM DD YYYY")}</td>
                  <td><Button
                    size="sm"
                    className={
                      row.status == 0
                        ? "pending_btn"
                        : row.status == 1
                          ? "sent_btn"
                          : "failed_btn"
                    }
                  >
                    {row.status === 0 ? "Pending" : row.status === 1 ? "Sent" : "Failed"}
                  </Button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleClose}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
