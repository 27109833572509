import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContextProvider";
import { Link } from 'react-router-dom';
import APIServicenew from "../utils/APIGeneralService";
import { getCookies, setCookies } from "../utils/Helper";


//import toast from "react-hot-toast";
import {
  Button,
  Input,
  Form
} from "reactstrap";
import PublicLayout from '../components/PublicLayout/publicLayout';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [questions, setQuestion] = useState([]);
  const [questionSelected, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState();
  const [load, setLoad] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getControlQuestion();
  }, []);
  // get Control Question 
  const getControlQuestion = async () => {

    try {
      //call get profile API
      const response = await APIServices.get(
        `getControlQuestion`,
        headers
      );
      if (response?.status == 200) {
        const resp = response?.data?.list || [];
        setQuestion(resp)
        console.log("resp?.list", resp)
      } else if (response?.status === 401) {
        navigate("/");
      } else {
        setQuestion([])
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const validateQuestionInfo = () => {
    const errors = {};
    if (!answer) {
      errors.answer = "Answer is required.";
    }
    if (!questionSelected) {
      errors.questionSelected = "Select question is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  //submit question and answer of user
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (validateQuestionInfo()) {
      try {
        let email = getCookies("forgotEmail");
        const question = questions.find(q => q.id == questionSelected);
        let payload = {
          email: email,
          question_id: questionSelected,
          question: question?.question,
          answer: answer
        }
        //call API for profile update
        const res = await APIServices.post("matchControlQuestion", payload, headers);
        if (res?.data?.status === true) {

        } else {

        }
        setLoad(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <PublicLayout>
      <section className="login_page_inner oborna_login_sec ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <Form role="form" onSubmit={handleSubmit}>
                <div className="login-form psw-recovery">
                  <div className="text-center top">
                    <img src="/images/oborna.svg" className="img-fluid mb-5" alt="logo" />

                    <img src="/images/cross_icon.svg" className="img-fluid incorrect_icon" alt="incorrect"/>
                    <h1 className="login-heading text-center font_24 font_700">The Answer Is Incorrect</h1>
                    <p className="login-subheading text-center font_12 font_400 mb-3">
                      The data entered by you are incorrect. Try again <br></br> or contact the administrator to restore access <br></br> to the account
                    </p>
                  </div>

                  <div className="text-center d-flex align-items-center otp_btn flex-column gap-3">
                    <Link
                      className="font_12 font_700 d-block w-100"
                      to="/control-question"
                    >
                      <Button
                        className="btn sent_btn font_black_14 w-100 py-2 font_700"
                        type="button"
                      >
                        Try Again
                      </Button>
                    </Link>
                    <Link
                      className="font_12 font_700 d-block w-100"
                      to="/"
                    >
                      <Button
                        className="btn sent_btn font_black_14 w-100 py-2 font_700"
                        type="button"
                      >
                        Back To Login Page
                      </Button>
                    </Link>

                  </div>
                </div>
              </Form>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="login-right-image grey_box row_col_full">
                <img src="/images/login_sideimage.svg" className="img-fluid login_sideimage" alt="login image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>

  );
};

export default ForgetPassword;
