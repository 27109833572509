import { useContext, useEffect, useRef, useState } from "react";
import AuthLayout from "../components/PublicLayout/authLayout";
import { useNavigate, Link, useLocation } from "react-router-dom";
import moment from "moment";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  CardBody,
  CardTitle,
  Form
} from "reactstrap";
import PaginatedTable from "../components/Table/PaginatedTable";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../utils/Helper";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../components/Modal/deleteModal";
import GroupDataModal from "../components/Modal/GroupDataModal";

import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for the date picker

const Message = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [limit, setLimit] = useState(10);
  const [data1, setData1] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [tags, setTags] = useState("");
  const [fullname, setFullname] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [reset, setReset] = useState(false);
  const offset = (currentPage - 1) * limit;
  const [sort, setSort] = useState("newest");
  const [showDt, setShowDt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDeleteMsg, setSelectedDeleteMsg] = useState(null);
  const [activeTab, setActiveTab] = useState("0");

  const messageData = location.state;
  const [templateList, setTemplateList] = useState([]);
  const [templateId, setTemplateId] = useState(0);
  const [mobileNumber, setMobileNumber] = useState(
    messageData?.mobile_number || ""
  );
  const [name, setName] = useState(messageData?.name || "");
  const [messageType, setMessageType] = useState(messageData?.type || "");
  const [repeat, setRepeat] = useState(messageData?.repeat || "");
  const [messageId, setMessageId] = useState();

  const [scheduleDate, setScheduleDate] = useState(
    messageData && messageData?.schedule_date
      ? new Date(messageData?.schedule_date).toISOString().split("T")[0]
      : ""
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const [yourMessage, setYourMessage] = useState(messageData?.message || "");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const [countryCode, setCountryCode] = useState(messageData?.country_code || "+1"); // Default country code
  const { setAccessToken } = useContext(UserContext);
  const today = new Date().toISOString().split("T")[0];

  const [templateData, setTemplateData] = useState([]);
  const [viewTemplate, setViewTemplate] = useState();


  const colors = ['bg_lightgreen', 'bg_lightorange', 'bg_skyblue', 'bg_lightpurple', 'bg_lightpink', 'bg_lightyellow'];
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [groupId, setGroupId] = useState();
  const [images, setImages] = useState([]); // State to store selected images
  const [myFile, setMyFile] = useState([]); // State to store selected images
  const [viewSMSPreview, setViewSMSPreview] = useState(false);
  const [viewPaperPreview, setViewPaperPreview] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false);
  const [showGroupData, setShowGroupData] = useState(false);

  const [recipients, setRecipients] = useState([
    { name: '', mobileNumber: '', countryCode: '+1' }
  ]);
  const [paperRecipients, setPaperRecipients] = useState([
    { name: '', address: '', city: '', country: '', zipcode: '' }
  ]);
  const addPaperMore = () => {
    setPaperRecipients([
      ...paperRecipients,
      { name: '', address: '', city: '', country: '', zipcode: '' }, // Adding a new recipient with blank fields
    ]);
  };

  const handlePaperFieldChange = (index, field, value) => {
    const updatedRecipients = [...paperRecipients];
    updatedRecipients[index][field] = value;
    setPaperRecipients(updatedRecipients);
  };

  const removePaperRecipient = (index) => {
    const updatedRecipients = paperRecipients.filter((_, idx) => idx !== index);
    setPaperRecipients(updatedRecipients);
  };

  const countryPaperOptions = [
    { value: 'Albania', label: 'Albania' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Czechia', label: 'Czechia' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Holy See', label: 'Holy See' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Moldova', label: 'Moldova' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'North Macedonia', label: 'North Macedonia' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russia', label: 'Russia' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'UK', label: 'UK' },
    { value: 'USA', label: 'USA' }
  ];


  // Handle the change event
  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Manually format the selected date to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0'); // Pad the day with a leading zero if necessary

    const formatted = `${year}-${month}-${day}`;
    setScheduleDate(formatted);
  };

  // Function to add a new recipient
  const addMore = () => {
    setRecipients([
      ...recipients,
      { name: '', mobileNumber: '', countryCode: '+1' }, // Adding a new recipient with blank fields
    ]);
  };

  // Function to handle input field changes
  const handleFieldChange = (index, field, value) => {
    const updatedRecipients = [...recipients];
    updatedRecipients[index][field] = value;
    setRecipients(updatedRecipients); // Update the state with modified data
  };

  // Function to remove a recipient
  const removeRecipient = (index) => {
    const updatedRecipients = recipients.filter((_, i) => i !== index);
    setRecipients(updatedRecipients);
  };

  // Handle image change (file selection)
  const handleImageChange = (event) => {
    const files = event.target.files;
    const validTypes = ['image/jpeg', 'image/png', 'image/gif']; // List supported image types
    const validFiles = Array.from(files).filter(file => validTypes.includes(file.type));

    if (validFiles.length !== files.length) {
      showToast("Some files are not valid image types.", "error");
    }

    setMyFile(validFiles);

    // Create new array for image previews
    const newImages = [];

    validFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        newImages.push(reader.result);
        setImages((prevImages) => [...prevImages, reader.result]); // Update preview images
      };
      reader.readAsDataURL(file); // Read each file as a data URL for preview
    });
  };

  // Handle image deletion (remove image from preview and files)
  const handleDeleteImage = async (index, image_name, messageId) => {
    // Remove from images array
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    // Remove from the myFile array
    const updatedFiles = [...myFile];
    updatedFiles.splice(index, 1);
    setMyFile(updatedFiles);
    //call api for remove images
    if (image_name != "") {
      const payload = { imageName: image_name, id: messageId };
      const res = await APIServices.post(`/removeImage`, payload, headers);
      if (res?.status === 200) {
      } else {
      }
    }
  };
  // validation function SMS
  const validate = () => {
    const errors = {};

    // Loop through each recipient and validate their fields
    recipients.forEach((recipient, index) => {
      if (!recipient.name) {
        errors.name = `Contact Name is required.`;
      }
      if (!recipient.mobileNumber) {
        errors.mobileNumber = `Phone number is required.`;
      }
    });
    if (!scheduleDate) {
      errors.scheduleDate = "Schedule Date is required.";
    }
    if (!yourMessage) {
      errors.yourMessage = "Message is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // validation function paper letter
  const validatePaper = () => {
    const errors = {};
    if (paperRecipients.length == 0) {
      errors.noRecipients = "Please add any recipient!";
      showToast("Please add any recipient!", "error");
    }
    // Loop through each recipient and validate their fields
    paperRecipients.forEach((recipient, index) => {
      if (!recipient.name) {
        errors.name = `Contact Name is required.`;
      }
      if (!recipient.address) {
        errors.address = `Address is required.`;
      }
      if (!recipient.city) {
        errors.city = `City is required.`;
      }
      if (!recipient.country) {
        errors.country = `Country is required.`;
      }
      if (!recipient.zipcode) {
        errors.zipcode = `Zip Code is required.`;
      }

    });
    if (!scheduleDate) {
      errors.scheduleDate = "Schedule Date is required.";
    }
    if (!yourMessage) {
      errors.yourMessage = "Message is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //handle submit details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    // Check if the message object is defined (for editing)
    //call common validation function
    if (validate()) {
      // Create the payload object
      let payload = {

        recipients: recipients,
        type: "SMS",
        schedule_date: scheduleDate,
        message: yourMessage,
        template_id: templateId,
        repeat: Number(repeat),
      };
      // Check if message.id exists, and if so, add it to the payload
      if (groupId) {
        payload.id = groupId;
        try {
          //call API for edit message
          const res = await APIServices.post("editMessage", payload, headers);
          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while editing the message.", "error");
        }
      } else {
        try {
          //call API for add message
          const res = await APIServices.post("addMessage", payload, headers);

          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while adding the message.", "error");
          console.error("Error adding message:", error);
        }
      }
      setLoad(false);
    } else {
      setLoad(false);
    }
  };

  //handle submit details
  const handlePaperSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    // Check if the message object is defined (for editing)
    //call common validation function
    if (validatePaper()) {

      const payload = new FormData();
      // Append form data including the non-file fields
      payload.append("recipients", JSON.stringify(paperRecipients));
      payload.append("type", "Paper Letter");
      payload.append("schedule_date", scheduleDate);
      payload.append("message", yourMessage);
      payload.append("template_id", templateId);
      payload.append("repeat", Number(repeat));

      payload.append("images", []);
      if (images.length > 0) {
        payload.append("images", images);
      }
      // Append all selected files (multiple images)
      if (myFile && myFile.length > 0) {
        for (let i = 0; i < myFile.length; i++) {
          const file = myFile[i];
          payload.append('files', file); // Append each file to FormData for server upload
        }
      }
      let headers = {
        "auth-token": authToken,
        authorization: accessToken,
        "Content-Type": "multipart/form-data", // This is usually set automatically by the browser
      };

      // Check if message.id exists, and if so, add it to the payload
      if (groupId) {
        payload.append("id", groupId);
        try {
          //call API for edit message
          const res = await APIServices.post("editPaperMessage", payload, headers);

          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while editing the message.", "error");
        }
      } else {
        try {
          //call API for add message
          const res = await APIServices.post("addPaperLetter", payload, headers);

          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while adding the message.", "error");
          console.error("Error adding message:", error);
        }
      }
      setLoad(false);
    } else {
      setLoad(false);
    }
  };

  //get list of message from DB
  const fetchData = async (page = 1) => {
    const skip = page;
    setLoading(true)
    try {
      //add filtets
      const queryParams = [];
      if (keyword) queryParams.push(`keyword=${keyword}`);
      if (limit) queryParams.push(`limit=${limit}`);
      if (skip) queryParams.push(`offset=${skip}`);
      if (sort) queryParams.push(`sort_by=${sort}`);
      //call message list API
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getMessage${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setData1(data);
        setCount(response?.data?.count);
        const totalItems = response?.data?.count || 0;
        setTotalCount(Math.ceil(totalItems / limit));
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setData1([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData1([]);
      setTotalCount(0);
    }
    setLoading(false)
  };

  const getGroupData = async (group_id) => {
    if (group_id != "") {
      setGroupData([])
      setGroupLoading(true)
      setShowGroupData(true)
      try {
        //add filtets
        const queryParams = [];
        if (group_id) queryParams.push(`group_id=${group_id}`);
        const queryString =
          queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
        const response = await APIServices.get(
          `getMessagebyGroup${queryString}`,
          headers
        );
        if (response?.status === 200) {
          const data = response?.data?.list || [];
          setGroupData(data);
          setGroupLoading(false)
        } else if (response?.status === 401) {
          logout();
          navigate("/");
          setGroupLoading(false)
        } else {
          setGroupData([]);
          setGroupLoading(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setGroupData([]);
        setGroupLoading(false)
      }
      setGroupLoading(false)
    }
  };

  // State to keep track of sorting direction and column
  const [sortDirection, setSortDirection] = useState('desc');
  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc'; // Toggle direction
    setSortDirection(newDirection);

    // Sort data based on the new direction
    const sorted = [...data1].sort((a, b) => {
      // First compare by 'type'
      const typeComparison = newDirection === 'asc'
        ? a.type.localeCompare(b.type)
        : b.type.localeCompare(a.type);

      // If types are equal, compare by 'name'
      if (typeComparison === 0) {
        const nameComparison = newDirection === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);

        // If names are equal, compare by 'schedule_date'
        if (nameComparison === 0) {
          const dateA = new Date(a.schedule_date);
          const dateB = new Date(b.schedule_date);


          return newDirection === 'asc'
            ? dateA - dateB // Ascending order for dates
            : dateB - dateA; // Descending order for dates
        }

        return nameComparison;
      }

      return typeComparison;
    });

    setData1(sorted);
  };

  const toggle = (tab) => {
    closeTemplate()
    if (tab == 1) {
      setViewSMSPreview(false)
    } else if (tab == 4) {
      setViewPaperPreview(false)
    }
    if (activeTab !== tab) setActiveTab(tab);
  };

  const cancelSMS = () => {
    window.location.reload();
  };

  //open sms templates
  const openTemplate = async () => {
    getSMSTemplates()
    setViewTemplate("add_temp")
    setIsVisible(true);
  };
  const closeTemplate = async () => {
    setViewTemplate("")
    setIsVisible(false);
    setTags();
  };

  //get text of template from object
  const getTemplateTextById = (id) => {
    const template = templateList.find(template => template.id === id);
    return template ? template.text : null;
  };


  const editMessage = async (row) => {
    if (row?.type == "SMS") {
      toggle("1")
    } else if (row?.type == "MMS") {
      toggle("2")
    } else if (row?.type == "Paper Letter") {
      toggle("4")
    }
    let groupId = row?.group_id;

    setLoading(true)
    setMessageId(row?.id)
    try {
      const queryParams = [];
      if (groupId) queryParams.push(`group_id=${groupId}`);
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getMessagebyId${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        let json_data = JSON.parse(data?.json_data);

        if (row?.type == "SMS") {
          setRecipients(json_data)
        } else if (row?.type == "MMS") {
          setRecipients(json_data)
        } else if (row?.type == "Paper Letter") {
          setPaperRecipients(json_data)
        }
      } else if (response?.status === 401) {
        console.error("Error fetching data:");
      } else {
        console.error("Error fetching data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRecipients([]);
      setPaperRecipients([])
    }

    setMessageType(row?.type)
    let date = row?.schedule_date;
    date = new Date(date).toISOString().split("T")[0]
    if (row?.type == "Paper Letter") {
      date = new Date(date); // Ensure this is a Date object
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedDate = `${month}/${day}`;
      setSelectedDate(formattedDate)
      setScheduleDate(formattedDate)
    } else {
      setScheduleDate(date)

    }
    setYourMessage(row?.message)
    setRepeat(row?.repeat)
    setGroupId(groupId)
    let currentImages = row?.images;
    if (currentImages && currentImages.length > 0) {
      const imageArray = currentImages.split(', ').map(url => url.trim());
      setImages(imageArray);
    }
    setLoading(false)
  };

  const previewMessage = async (row) => {
    if (row?.type == "SMS") {
      toggle("1")
      setViewSMSPreview(true)
    } else if (row?.type == "MMS") {
      toggle("2")
    } else if (row?.type == "Paper Letter") {
      toggle("4")
      setViewPaperPreview(true)
    }
    let groupId = row?.group_id;
    setLoading(true)
    setMessageId(row?.id)
    try {
      const queryParams = [];
      if (groupId) queryParams.push(`group_id=${groupId}`);
      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getMessagebyId${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        let json_data = JSON.parse(data?.json_data);

        if (row?.type == "SMS") {
          setRecipients(json_data)
        } else if (row?.type == "MMS") {
          setRecipients(json_data)
        } else if (row?.type == "Paper Letter") {
          setPaperRecipients(json_data)
        }
      } else if (response?.status === 401) {
        console.error("Error fetching data:");
      } else {
        console.error("Error fetching data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRecipients([]);
      setPaperRecipients([])
    }

    setMessageType(row?.type)
    let date = row?.schedule_date;
    date = new Date(date).toISOString().split("T")[0]
    if (row?.type == "Paper Letter") {
      date = new Date(date); // Ensure this is a Date object
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedDate = `${month}/${day}`;
      setSelectedDate(formattedDate)
      setScheduleDate(formattedDate)
    } else {
      setScheduleDate(date)

    }
    setYourMessage(row?.message)
    setRepeat(row?.repeat)
    setGroupId(groupId)
    let currentImages = row?.images;
    if (currentImages && currentImages.length > 0) {
      const imageArray = currentImages.split(', ').map(url => url.trim());
      setImages(imageArray);
    }
    setLoading(false)
  };

  //handle choose sms template 
  const handleChooseTemplate = async () => {
    let templateText = getTemplateTextById(templateId);
    setYourMessage(templateText)
    setShowDt(false)
  };

  useEffect(() => {
    //initial call of fetch message data
    getSMSTemplates();
  }, [sort, keyword, tags]);
  //open sms templates
  const getSMSTemplates = async () => {
    setLoading(true)
    try {
      const queryParams = [];
      if (keyword) queryParams.push(`keyword=${keyword}`);
      if (tags) queryParams.push(`tags=${tags}`);
      if (sort) queryParams.push(`sort_by=${sort}`);
      //call sms template list API

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      const response = await APIServices.get(
        `getSMSTemplates${queryString}`,
        headers
      );
      if (response?.status === 200) {
        const data = response?.data?.list || [];
        setTemplateData(data);
        setTemplateList(data)
      } else if (response?.status === 401) {
        logout();
        navigate("/");
      } else {
        setTemplateData([]);
        setTemplateList([])

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setTemplateData([]);
      setTemplateList([])

    }
    setLoading(false)
  };

  const handleTemplateClick = (id) => {
    setSelectedTemplateId(id);
    setTemplateId(id)
    let templateText = getTemplateTextById(id);
    setYourMessage(templateText)
  };

  useEffect(() => {
    //initial call of fetch message data
    fetchData(currentPage);
  }, [currentPage, limit, sort, keyword]);

  //make coloums for message list table
  const columns = [
    {
      dataField: "type",
      text: (
        <>
          <span onClick={handleSort}>
            Type
            <i class="fa-solid fa-sort-down" style={{ marginLeft: "8px" }}></i>
          </span>
        </>
      ),

    },
    {
      dataField: "name",
      text: (
        <>
          <span onClick={handleSort}>
            Contact Name
            <i class="fa-solid fa-sort-down" style={{ marginLeft: "8px" }}></i>
          </span>
        </>
      ),
      formatter: (cell, row) => {
        let count = row?.messageCount - 1;
        return (
          <>
            <div className="d-flex align-items-center gap-2">
              <div className="table_user_title">
                <img src="/images/user_icon.svg" />
                <span>
                  {capitalizeFirstLetter(row.name)}
                </span>

              </div>
              {count > 0 ? (<span className="group_count" onClick={() => getGroupData(row?.group_id)}>
                {"+" + count + "..."}
              </span>) : ("")}
            </div>
          </>
        );
      },
    },
    {
      dataField: "mobile_number",
      text: (
        <>
          <span onClick={handleSort}>
            Phone Number
            <i class="fa-solid fa-sort-down" style={{ marginLeft: "8px" }}></i>
          </span>
        </>
      ),
      formatter: (cell, row) =>
      row.mobile_number ? "(" + row.country_code + ")" + " " + row.mobile_number : "-",
    },
    {
      dataField: "address",
      text: (
        <> Address
        </>
      ),
      formatter: (cell, row) =>
      row.address ? row.address + ", " + row.city +", " + row.zipcode +", " + row.country : "-",
    },

    {
      dataField: "schedule_date",
      text: (
        <>
          <span onClick={handleSort}>
            Schedule Date
            <i class="fa-solid fa-sort-down" style={{ marginLeft: "8px" }}></i>
          </span>
        </>
      ),
      formatter: (cell, row) => moment(row.schedule_date).format("MMM DD YYYY"),
    },
    {
      dataField: "status",
      text: (
        <>
          <span onClick={handleSort}>
            Status
            <i class="fa-solid fa-sort-down" style={{ marginLeft: "8px" }}></i>
          </span>
        </>
      ),
      formatter: (cell, row) => (
        <Button
          size="sm"
          className={
            row.status == 0
              ? "pending_btn"
              : row.status == 1
                ? "sent_btn"
                : "failed_btn"
          }
        >
          {row.status === 0 ? "Pending" : row.status === 1 ? "Sent" : "Failed"}
        </Button>
      ),
    },
    {
      // dataField: "schedule_date",
      text: "Preview",
      formatter: (cell, row) => {
        // Assuming the preview URL can be dynamically generated or it's a static path
        const previewUrl = `/message-preview/${row.id}`; // Adjust the URL according to your requirement

        return (
          <>
            <div className="action_icon_wrapper">
              <span
                className="action_icon"
                onClick={() => previewMessage(row)
                }
              >
                <img src="/images/preview_icon.svg" />
              </span>
            </div>
          </>
        );
      },
    },
    {
      // dataField: "schedule_date",
      text: "Edit",
      formatter: (cell, row) => {
        return (
          <>
            {row?.is_sent == 0 ? (
              <>
                <div className="action_icon_wrapper">
                  <span
                    className="action_icon"
                    onClick={() => editMessage(row)
                    }
                  >
                    {/* <i class="fa-solid fa-pencil"></i> */}
                    <img src="/images/edit_icon.svg" />
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      // dataField: "schedule_date",
      text: "Delete",
      formatter: (cell, row) => {
        return (
          <>
            {row?.is_sent == 0 ? (
              <>
                <div className="action_icon_wrapper">
                  <span
                    className="action_icon"
                    onClick={() => {
                      setSelectedDeleteMsg(row?.id);
                      setShowDt(true);
                    }}
                  >
                    <img src="/images/delete_icon.svg" />
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (reset) {
      fetchData();
      setCurrentPage(1);
      setReset(false);
    }
  }, [reset]);

  const countryOptions = [
    {
      value: "+1",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +1
          <img
            src="/images/flags/us.png"
            alt="US"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+48",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +48
          <img
            src="/images/flags/pl.png"
            alt="PL"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+91",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +91
          <img
            src="/images/flags/in.png"
            alt="IN"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+44",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +44
          <img
            src="/images/flags/gb.png"
            alt="UK"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
    {
      value: "+61",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          +61
          <img
            src="/images/flags/au.png"
            alt="AUS"
            style={{ width: "20px", height: "18px", marginLeft: "6px" }}
          />
        </div>
      ),
    },
  ];



  //handle delete message 
  const handleDelete = async () => {
    try {
      if (selectedDeleteMsg !== null) {
        const payload = { id: selectedDeleteMsg };
        //call delete message api
        const res = await APIServices.post(`/deleteMessage`, payload, headers);
        if (res?.status === 200) {
          showToast(res?.data?.message || "Success", "success");
          setSelectedDeleteMsg(null);
          fetchData();
          setCurrentPage(1);
        } else {
          showToast(res?.data?.message || "Something went wrong.", "error");
        }
      }
      setShowDt(false);
    } catch (error) {
      console.error("Error deleting Message:", error);
    }
  };

  //show success and fail message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <AuthLayout>
      <div className="content-inner messaging">
        <div className="overflow-content">
          <div className="customizer_wrapper">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => toggle("1")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/sms_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">SMS</h4>
                    <p className="card_para font_12">Send text message to phone without media</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => toggle("2")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">MMS</h4>
                    <p className="card_para font_12">Send text message to phone with media: images, ringtones, videos</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => toggle("3")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">E-Mail</h4>
                    <p className="card_para font_12">Send email with media: images, ringtones, videos</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => toggle("4")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">Paper Letter</h4>
                    <p className="card_para font_12">Write a message, add an image, and we will print and deliver it!</p>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => toggle("5")}
                >
                  <div className="customizer_link bg-black-theme" href="#">
                    <img src="/images/MMS_icon.svg" className="customizer_card_icon" />
                    <h4 className="card_heading font_16 mb-2 font_700">Add Item In Storage</h4>
                    <p className="card_para font_12">Write a message, add an image, and we will print and deliver it!</p>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="0">
                <Card className="p-0">
                  <CardHeader className="border-0 bg-white-theme p-0">
                    <h3 className="mb-0 card-title font_18">
                      Schedules
                    </h3>

                    <div className="d-flex gap-1 mb-3">
                      <div className="select-filter-wrapper w-25 ms-auto search-wrapper h-38 mb-0">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <Input
                          className="search_message h-38 font_12"
                          placeholder="Search by contact name, keywords, etc."
                          value={keyword}
                          type="text"
                          onChange={(e) => setKeyword(e.target.value)}
                        />
                      </div>
                      <div className="message-filter-wrapper select-filter-wrapper flex-nowrap">
                        <p className="font_12 w-nowrap">Short by : </p>
                        <div className="position-relative d-flex gap-3">
                          <Input
                            type="select"
                            class="form-select font_12"
                            aria-label="Default select example"
                            onChange={(e) => setSort(e.target.value)}
                          >
                            <option selected value="newest">
                              Newest
                            </option>
                            <option value="oldest">Oldest</option>
                            {/* More country codes as needed */}
                          </Input>
                          <i class="fa-solid fa-angle-down"></i>
                        </div>
                      </div>
                    </div>


                  </CardHeader>

                  {/* {data1 && Array.isArray(data1) && data1.length !== 0 ? ( */}
                  {loading == false ? (
                    <PaginatedTable
                      data={data1}
                      columns={columns}
                      totalPages={totalCount}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                      limit={limit}
                      setLimit={setLimit}
                    />
                  ) : (
                    <h2 className="text-center mb-4">Loading...</h2>
                  )}
                </Card>
              </TabPane>

              <TabPane tabId="1">
                <Row>
                  <Col sm="12" md="12" xl="12" className="col-xxl-12">
                    <Form role="form" onSubmit={handleSubmit}>
                      {viewSMSPreview ? (
                        <ul className={`sms_content_wrapper ${viewTemplate}`}>
                          <li className="delivery_info_wrapper bg-white-theme">
                            <h3 className="delivery_heading font_18 font_700 mb-3">Delivery Information</h3>
                            <div className="add_more">

                              {recipients && recipients.length > 0 && recipients.map((recipient, index) => (
                                <div key={index} className="recipient-item">
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`name-${index}`} className="form-label font_black_12 font_700">
                                        Contact {index + 1} Name
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`name-${index}`}
                                        name={`name-${index}`}
                                        placeholder="Enter Name"
                                        type="text"
                                        value={recipient.name}
                                        maxLength={30}
                                        disabled={true}
                                        readOnly={true}
                                      />

                                    </Col>
                                  </FormGroup>

                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`recipientNumber-${index}`} className="form-label font_black_12 font_700">
                                        Phone Number
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <InputGroup className="d-flex align-items-center phn_code">

                                        <Select
                                          className="form_input down_arw"
                                          classNamePrefix="react-select"
                                          options={countryOptions}
                                          value={countryOptions.find((option) => option?.value === recipient?.countryCode)}
                                          placeholder="Select Country"
                                          isSearchable={false}
                                          readOnly={true}
                                          isDisabled={true} />

                                        <Input
                                          className="form-control form_input font_black_12"
                                          id={`recipientNumber-${index}`}
                                          name={`phoneNumber-${index}`}
                                          placeholder="Enter Phone No"
                                          type="tel"
                                          value={recipient.mobileNumber}
                                          disabled={true}
                                          readOnly={true}
                                          maxLength={10}
                                          style={{ borderRadius: "8px", marginLeft: "5px" }}
                                        />
                                      </InputGroup>

                                    </Col>
                                  </FormGroup>
                                </div>
                              ))}
                            </div>
                            <FormGroup className=" mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="scheduleDate" className="form-label font_black_12 font_700">
                                  Scheduled Date
                                </label>
                              </Col>
                              <Col sm="8">
                                <Input
                                  className="form-control form_input font_black_12"
                                  id="scheduleDate"
                                  name="scheduleDate"
                                  type="date"
                                  value={scheduleDate}
                                  min={today}
                                  disabled={true}
                                  readOnly={true}
                                />

                              </Col>
                            </FormGroup>

                            <FormGroup className="mt-2 mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="repeat" className="form-label font_black_12 font_700">
                                  Repeat
                                </label>
                              </Col>
                              <Col sm="8">
                                <div className="down_arw">
                                  <Input
                                    type="select"
                                    id="repeat"
                                    name="repeat"
                                    className="form-control form_input font_black_12"
                                    value={repeat || "1"}
                                    disabled={true}
                                    readOnly={true}
                                  >
                                    <option value="0">No Repeat</option>
                                    <option value="1">Every 1 Year</option>
                                    <option value="2">Every 2 Year</option>
                                    <option value="3">Every 3 Year</option>
                                    <option value="4">Every 4 Year</option>
                                    <option value="5">Every 5 Year</option>
                                  </Input>
                                </div>
                              </Col>
                            </FormGroup>
                          </li>

                          <li className="del_textarea_wrapper bg-white-theme">
                            {/* <Col className="col-sm-12 col-lg-6 col-xl-4 col-xxl-4"> */}
                            <FormGroup className="msg_area">
                              <label htmlFor="yourMessage" className="form-label font_18 font_700 mb-3">
                                Text And Media
                              </label>
                              <div className="d-flex gap-2 position-relative ">
                                <Input
                                  className="form-control form_input font_black_12 h-auto"
                                  id="yourMessage"
                                  name="yourMessage"
                                  placeholder="Write here..."
                                  type="textarea"
                                  rows="18"
                                  value={yourMessage}
                                  maxLength="1000"
                                  disabled={true}
                                  readOnly={true}
                                />

                                <span class="textarea_count">{yourMessage.length}/1000</span>
                              </div>

                            </FormGroup>

                            <div className="d-flex justify-content-end align-items-center">
                              <FormGroup className="mb-3 mt-1 align-items-center justify-content-end template_buttons">
                                <Button className="btn smoke_btn font_700" onClick={() => cancelSMS()} type="button">
                                  Cancel
                                </Button>
                              </FormGroup>
                            </div>

                          </li>

                        </ul>
                      ) : (
                        <ul className={`sms_content_wrapper ${viewTemplate}`}>
                          <li className="delivery_info_wrapper bg-white-theme">
                            <h3 className="delivery_heading font_18 font_700 mb-3">Delivery Information</h3>
                            <div className="add_more">

                              {recipients && recipients.length > 0 && recipients.map((recipient, index) => (
                                <div key={index} className="recipient-item">
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`name-${index}`} className="form-label font_black_12 font_700">
                                        Contact {index + 1} Name
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`name-${index}`}
                                        name={`name-${index}`}
                                        placeholder="Enter Name"
                                        type="text"
                                        value={recipient.name}
                                        onChange={(e) => handleFieldChange(index, 'name', e.target.value)} // Update state on change
                                        maxLength={30}
                                      />
                                      {errors.name && (
                                        <div className="errorVal ">
                                          {errors.name}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>

                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`recipientNumber-${index}`} className="form-label font_black_12 font_700">
                                        Phone Number
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <InputGroup className="d-flex align-items-center phn_code">
                                        {/* <Input
                                        type="select"
                                        className="form-control font_black_12 form_input country-code"
                                        value={recipient.countryCode}
                                        onChange={(e) => handleFieldChange(index, 'countryCode', e.target.value)} // Update state on change
                                        style={{
                                          maxWidth: "60px",
                                          borderRadius: "8px",
                                          border: "1px solid #d3d5d7",
                                        }}
                                      >
                                        <option value="+1">US +1<img src="/images/logo.png"  /> </option>
                                        <option value="+48">PL +48</option>
                                        <option value="+91">IN +91</option>
                                        <option value="+44">UK +44</option>
                                        <option value="+61">AUS +61</option>
                                      </Input> */}


                                        <Select
                                          className="form_input down_arw"
                                          classNamePrefix="react-select"
                                          options={countryOptions}
                                          value={countryOptions.find((option) => option?.value === recipient?.countryCode)}
                                          onChange={(selectedOption) => handleFieldChange(index, "countryCode", selectedOption.value)}
                                          placeholder="Select Country"
                                          isSearchable={true} 
                                          />

                                        <Input
                                          className="form-control form_input font_black_12"
                                          id={`recipientNumber-${index}`}
                                          name={`phoneNumber-${index}`}
                                          placeholder="Enter Phone No"
                                          type="tel"
                                          value={recipient.mobileNumber}
                                          onChange={(e) => {
                                            // Ensure the value contains only numbers (using regex)
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                              handleFieldChange(index, 'mobileNumber', value); // Update state on change
                                            }
                                          }}
                                          maxLength={10}
                                          style={{ borderRadius: "8px", marginLeft: "5px" }}
                                        />
                                      </InputGroup>
                                      {errors.mobileNumber && (
                                        <div className="errorVal ">
                                          {errors.mobileNumber}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>

                                  {recipients.length > 1 && (
                                    <>
                                      <div className="row align-items-center mb-3">
                                        <Col sm="4" className="pe-0"></Col>
                                        <Col sm="8">
                                          <Button
                                            color="danger"
                                            onClick={() => removeRecipient(index)}
                                            style={{ marginTop: "10px", marginBottom: "10px" }}
                                            className="btn danger-btn w-100 font_white_14"
                                          >
                                            Remove Recipient
                                          </Button>
                                        </Col>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}

                              {/* Add Another Recipient Button */}
                              <div className="row align-items-center mb-3">
                                <Col sm="4" className="pe-0"></Col>
                                <Col sm="8">
                                  <Button
                                    className="btn primary-btn w-100 font_white_14 font_700"
                                    style={{ marginTop: "10px" }}
                                    onClick={addMore}
                                  >
                                    Add Another Recipient
                                  </Button>
                                </Col>
                              </div>
                            </div>
                            <FormGroup className=" mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="scheduleDate" className="form-label font_black_12 font_700">
                                  Scheduled Date
                                </label>
                              </Col>
                              <Col sm="8">
                                <Input
                                  className="form-control form_input font_black_12"
                                  id="scheduleDate"
                                  name="scheduleDate"
                                  type="date"
                                  value={scheduleDate}
                                  min={today}
                                  onChange={(e) => setScheduleDate(e.target.value)}
                                />
                                {errors.scheduleDate && (
                                  <div className="errorVal ">
                                    {errors.scheduleDate}
                                  </div>
                                )}
                              </Col>
                            </FormGroup>

                            <FormGroup className="mt-2 mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="repeat" className="form-label font_black_12 font_700">
                                  Repeat
                                </label>
                              </Col>
                              <Col sm="8">
                                <div className="down_arw">
                                  <Input
                                    type="select"
                                    id="repeat"
                                    name="repeat"
                                    className="form-control form_input font_black_12"
                                    value={repeat || "1"}
                                    onChange={(e) => setRepeat(e.target.value)}
                                  >
                                    <option value="0">No Repeat</option>
                                    <option value="1">Every 1 Year</option>
                                    <option value="2">Every 2 Year</option>
                                    <option value="3">Every 3 Year</option>
                                    <option value="4">Every 4 Year</option>
                                    <option value="5">Every 5 Year</option>
                                  </Input>
                                </div>

                                {errors.repeat && (
                                  <div className="errorVal mt-2">
                                    {errors.repeat}
                                  </div>
                                )}
                              </Col>

                            </FormGroup>
                            {/* <FormGroup className="mt-2 mb-3 row align-items-center">

                            <Col sm="4" className="pe-0">
                              <Button className="btn primary-btn w-100 font_white_14 btn btn-secondary" onClick={() => cancelSMS()} type="button">
                                Cancel
                              </Button>
                            </Col>
                            <Col sm="8">
                              {load ? (
                                <Button className="btn primary-btn " disabled>
                                  Processing...
                                </Button>
                              ) : (
                                <Button className="btn sky_btn w-100" type="submit">
                                  Add A New Schedule
                                </Button>
                              )}
                            </Col>
                          </FormGroup> */}
                          </li>

                          <li className="del_textarea_wrapper bg-white-theme">
                            {/* <Col className="col-sm-12 col-lg-6 col-xl-4 col-xxl-4"> */}
                            <FormGroup className="msg_area">
                              <label htmlFor="yourMessage" className="form-label font_18 font_700 mb-3">
                                Text And Media
                              </label>
                              <div className="d-flex gap-2 position-relative ">
                                <Input
                                  className="form-control form_input font_black_12 h-auto"
                                  id="yourMessage"
                                  name="yourMessage"
                                  placeholder="Write here..."
                                  type="textarea"
                                  rows="18"
                                  value={yourMessage}
                                  maxLength="1000"
                                  onChange={(e) => setYourMessage(e.target.value)}
                                />

                                <span class="textarea_count">{yourMessage?.length}/1000</span>
                              </div>
                              {errors.yourMessage && (
                                <div className="errorVal mt-2">
                                  {errors.yourMessage}
                                </div>
                              )}

                              <Button className="btn primary-btn w-auto mb-3 mt-3" onClick={() => openTemplate()}>
                                <img src="/images/template_icon.svg" className="" />
                                <div className="text-start">
                                  <span className="font_12">Don't know how to write better?</span>
                                  <p className="font_white_14 font_700"> You Can Choose Our Template For Message</p>
                                </div>
                              </Button>

                            </FormGroup>

                            <div className="d-flex justify-content-end align-items-center">
                              <FormGroup className="mb-3 mt-1 align-items-center justify-content-end template_buttons">
                                <Button className="btn smoke_btn font_700" onClick={() => cancelSMS()} type="button">
                                  Cancel
                                </Button>

                                {load ? (
                                  <Button className="btn smoke_btn  font_700" disabled>
                                    Processing...
                                  </Button>
                                ) : (
                                  <Button className="btn navy_btn font_700" type="submit">
                                    Add A New Schedule
                                  </Button>
                                )}

                              </FormGroup>
                            </div>

                          </li>

                          <li className="template_wrapper">
                            {isVisible && (
                              <>
                                <Row
                                  className="modal-header-hd justify-content-between mb-4"
                                >
                                  <Col
                                    xs="4" className="font_18 font_700">
                                    Text Templates
                                  </Col>
                                  <Col
                                    xs="4"
                                    className="d-flex align-items-center justify-content-end"
                                  >
                                    <div className="select-filter-wrapper search-wrapper mx-0 w-100 h-38 mb-0">
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                      <Input
                                        className="search_message h-38 font_12"
                                        placeholder="Search by keywords"
                                        value={keyword}
                                        type="text"
                                        onChange={(e) => setKeyword(e.target.value)}
                                      />
                                    </div>
                                  </Col>

                                  <Col
                                    xs="4" className="text-end">
                                    <Button className="btn primary-btn h-38 font_white_14" onClick={() => closeTemplate()}>
                                      Close Templates
                                    </Button>
                                  </Col>
                                </Row>

                                <ul className="template_multibtn pb-2">
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "New Year" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "New Year" ? "" : "New Year")}
                                    >
                                      New Year
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Birthday" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Birthday" ? "" : "Birthday")}
                                    >
                                      Birthday
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Fathers day" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Fathers day" ? "" : "Fathers day")}
                                    >
                                      Fathers day
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Mothers day" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Mothers day" ? "" : "Mothers day")}
                                    >
                                      Mothers day
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Anniversary" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Anniversary" ? "" : "Anniversary")}
                                    >
                                      Anniversary
                                    </button>
                                  </li>
                                </ul>

                                <Row className="my-3 template_data_wrapper">
                                  {templateData.length === 0 ? (
                                    <div className="col-12 text-center">
                                      <p>No templates found.</p>
                                    </div>
                                  ) : (
                                    templateData.map((template, index) => {
                                      return (
                                        <Col
                                          sm="6"
                                          className={`mb-3 ${selectedTemplateId === template.id ? 'card_active' : ''}`}
                                          key={template.id}
                                          onClick={() => handleTemplateClick(template.id)}
                                          style={{ cursor: 'pointer' }} // Added cursor pointer style here
                                        >
                                          <p className="font_black_12 template_data">
                                            {template.text}
                                          </p>
                                        </Col>
                                      );
                                    })
                                  )}
                                </Row>

                                <FormGroup className="mb-3 align-items-center justify-content-end template_buttons">
                                  <Button className="btn smoke_btn font_700" onClick={() => cancelSMS()} type="button">
                                    Cancel
                                  </Button>

                                  {load ? (
                                    <Button className="btn smoke_btn  font_700" disabled>
                                      Processing...
                                    </Button>
                                  ) : (
                                    <Button className="btn navy_btn font_700" type="submit">
                                      Add A New Schedule
                                    </Button>
                                  )}

                                </FormGroup>
                              </>
                            )}
                          </li>
                        </ul>
                      )}

                    </Form>
                  </Col>
                </Row>
              </TabPane>


              <TabPane tabId="2">
                <Row>
                  <Col sm="12" md="12" xl="7" className="col-xxl-6">
                    <Form role="form" onSubmit={handleSubmit}>
                      <Row>
                        <Col sm="6">
                          <h3 className="delivery_heading font_18 font_700 mb-3">Delivery Information</h3>
                          <div className="add_more">
                            <FormGroup className=" mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="name" className="form-label font_black_14 font_700">
                                  Contact 1 Name
                                </label>
                              </Col>

                              <Col sm="8">
                                <Input
                                  className="form-control form_input font_black_14"
                                  id="name"
                                  name="name"
                                  placeholder="Enter Name"
                                  type="text"
                                  value={name}
                                  maxLength={30}
                                  onChange={(e) => setName(e.target.value)}
                                />
                                {errors.name && (
                                  <div className="errorVal ">{errors.name}</div>
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup className=" mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label
                                  htmlFor="recipientNumber"
                                  className="form-label font_black_14 font_700"
                                >
                                  Phone Number
                                </label>
                              </Col>
                              <Col sm="8">
                                <InputGroup className=" d-flex align-items-center">
                                  {/* Dropdown for country code */}
                                  <Input
                                    type="select"
                                    className="form-control font_black_14 form_input country-code"
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    style={{ maxWidth: "60px", borderRadius: "8px", border: "1px solid #d3d5d7" }} // Style for dropdown
                                  >
                                    <option value="+1">US +1</option>
                                    <option value="+48">PL +48</option>
                                    <option value="+91">IN +91</option>
                                    <option value="+44">UK +44</option>
                                    <option value="+61">AUS +61</option>
                                    {/* Add more country codes as needed */}
                                  </Input>

                                  {/* Phone number input */}
                                  <Input
                                    className="form-control form_input font_black_14"
                                    id="recipientNumber"
                                    name="phoneNumber"
                                    placeholder="Enter Phone No"
                                    type="tel"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    maxLength={10}
                                    style={{ borderRadius: "8px", marginLeft: "5px" }}
                                  />
                                </InputGroup>

                                {/* Error message for validation */}
                                {errors.mobileNumber && (
                                  <div className="errorVal ">
                                    {errors.mobileNumber}
                                  </div>
                                )}
                              </Col>
                            </FormGroup>
                          </div>
                          {/* <div className="row align-items-center  mb-3 ">
                            <Col sm="4" className="pe-0"></Col>
                            <Col sm="8">
                              <Button className="btn primary-btn w-100 font_white_14" >
                                Add Another Recipient
                              </Button>
                            </Col>
                          </div> */}
                          <FormGroup className=" mb-3 row align-items-center">
                            <Col sm="4" className="pe-0">
                              <label htmlFor="scheduleDate" className="form-label font_black_14 font_700">
                                Scheduled Date
                              </label>
                            </Col>
                            <Col sm="8">
                              <Input
                                className="form-control form_input font_black_14"
                                id="scheduleDate"
                                name="scheduleDate"
                                type="date"
                                value={scheduleDate}
                                min={today}
                                onChange={(e) => setScheduleDate(e.target.value)}
                              />
                              {errors.scheduleDate && (
                                <div className="errorVal ">
                                  {errors.scheduleDate}
                                </div>
                              )}
                            </Col>
                          </FormGroup>
                          <FormGroup className="mt-2 mb-3 row align-items-center">
                            <Col sm="4" className="pe-0">

                              <label htmlFor="messageType" className="form-label font_black_14 font_700">
                                Send This Message As
                              </label>
                            </Col>
                            <Col sm="8">
                              <div className="down_arw">
                                <Input
                                  type="select"
                                  id="messageType"
                                  name="messageType"
                                  className="form-control form_input font_black_16 mb-3"
                                  value={messageType}
                                  onChange={(e) => setMessageType(e.target.value)}
                                >
                                  <option value="">Select Message Type</option>
                                  <option value="MMS" >MMS</option>
                                  <option value="SMS">SMS</option>
                                </Input>
                              </div>
                              {errors.messageType && (
                                <div className="errorVal mt-2">
                                  {errors.messageType}
                                </div>
                              )}
                            </Col>

                          </FormGroup>
                          <FormGroup className="mt-2 mb-3 row align-items-center">
                            <Col sm="4" className="pe-0">
                              <label htmlFor="repeat" className="form-label font_black_12 font_700">
                                Repeat
                              </label>
                            </Col>
                            <Col sm="8">
                              <div className="down_arw">
                                <Input
                                  type="select"
                                  id="repeat"
                                  name="repeat"
                                  className="form-control form_input font_black_12 mb-3"
                                  value={repeat}
                                  onChange={(e) => setRepeat(e.target.value)}
                                >
                                  <option value="0">No Repeat</option>
                                  <option value="1">Every 1 Year</option>
                                  <option value="2">Every 2 Year</option>
                                  <option value="3">Every 3 Year</option>
                                  <option value="4">Every 4 Year</option>
                                  <option value="5">Every 5 Year</option>
                                </Input>
                              </div>

                              {errors.repeat && (
                                <div className="errorVal mt-2">
                                  {errors.repeat}
                                </div>
                              )}
                            </Col>

                          </FormGroup>
                          <FormGroup className="mt-2 mb-3 row align-items-center">

                            <Col sm="4" className="pe-0">
                              <Button className="btn primary-btn w-100 font_white_14 btn btn-secondary" type="button">
                                Cancel
                              </Button>
                            </Col>
                            <Col sm="8">
                              {load ? (
                                <Button className="btn primary-btn " disabled>
                                  Processing...
                                </Button>
                              ) : (
                                <Button className="btn sky_btn" type="submit">
                                  Add A New Schedule
                                </Button>
                              )}
                            </Col>
                          </FormGroup>
                        </Col>

                        <Col sm="6">
                          <FormGroup className="msg_area">
                            <label htmlFor="yourMessage" className="form-label font_18 font_700 mb-3">
                              Text And Media
                            </label>
                            <div className="d-flex gap-2 mb-3 position-relative " style={{ height: '396px' }}>
                              <Input
                                className="form-control form_input font_black_14 h-auto"
                                id="yourMessage"
                                name="yourMessage"
                                placeholder="Write here..."
                                type="textarea"
                                rows="17"
                                value={yourMessage}
                                maxLength="1000"
                                onChange={(e) => setYourMessage(e.target.value)}
                              />

                              <span class="textarea_count">{yourMessage.length}/1000</span>
                            </div>
                            {errors.yourMessage && (
                              <div className="errorVal my-2">
                                {errors.yourMessage}
                              </div>
                            )}
                            <Button className="btn primary-btn w-100 mb-3 " onClick={() => openTemplate()}>
                              <img src="/images/template_icon.svg" className="" />
                              <div className="text-start">
                                <span className="font_12">Don't know how to write better?</span>
                                <p className="font_white_14 font_700"> You Can Choose Our Template For Message</p>
                              </div>
                            </Button>
                          </FormGroup>

                        </Col>

                      </Row>
                    </Form>
                  </Col>

                  <Col sm="12" md="12" xl="5" className="col-xxl-6">
                    {isVisible && (
                      <>
                        <Row
                          className="modal-header-hd justify-content-between mb-4"
                        >
                          <Col
                            xs="4" className="font_18 font_700">
                            Text Templates
                          </Col>
                          <Col
                            xs="4"
                            className="d-flex align-items-center justify-content-end"
                          >
                            <div className="select-filter-wrapper search-wrapper mx-0 w-100 h-38 mb-0">
                              <i class="fa-solid fa-magnifying-glass"></i>
                              <Input
                                className="search_message h-38 font_12"
                                placeholder="Search by keywords"
                                value={keyword}
                                type="text"
                                onChange={(e) => setKeyword(e.target.value)}
                              />
                            </div>
                          </Col>

                          <Col
                            xs="4" className="text-end">
                            <Button className="btn primary-btn h-38 font_white_14" onClick={() => setIsVisible(false)}>
                              Close Templates
                            </Button>
                          </Col>
                        </Row>

                        <ul className="template_multibtn pb-2">
                          <li>
                            <button className="btn secondary-btn font_black_14" onClick={() => setTags("New Year")}>New Year</button>
                          </li>
                          <li>
                            <button className="btn secondary-btn font_black_14" onClick={() => setTags("Birthday")}>Birthday</button>
                          </li>
                          <li>
                            <button className="btn secondary-btn font_black_14" onClick={() => setTags("Fathers day")}>Fathers day</button>
                          </li>
                          <li>
                            <button className="btn secondary-btn font_black_14" onClick={() => setTags("Mothers day")}>Mothers day</button>
                          </li>
                          <li>
                            <button className="btn secondary-btn font_black_14" onClick={() => setTags("Anniversary")}>Anniversary</button>
                          </li>
                        </ul>
                        <Row className="my-3 template_data_wrapper">
                          {templateData.length === 0 ? (
                            <div className="col-12 text-center">
                              <p>No templates found.</p>
                            </div>
                          ) : (
                            templateData.map((template, index) => {
                              return (
                                <Col
                                  sm="6"
                                  className={`mb-3 ${selectedTemplateId === template.id ? 'card_active' : ''}`}
                                  key={template.id}
                                  onClick={() => handleTemplateClick(template.id)}
                                  style={{ cursor: 'pointer' }} // Added cursor pointer style here
                                >
                                  <p className="font_black_14 template_data">
                                    {template.text}
                                  </p>
                                </Col>
                              );
                            })
                          )}
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="3">
                tab coneten 3
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12" md="12" xl="12" className="col-xxl-12">
                    <Form role="form" onSubmit={handlePaperSubmit}>
                      {viewPaperPreview ? (
                        <ul className={`sms_content_wrapper ${viewTemplate}`}>
                          <li className="delivery_info_wrapper bg-white-theme">
                            <h3 className="delivery_heading font_18 font_700 mb-3">Delivery Information</h3>
                            <div className="add_more_paper">
                              {paperRecipients && paperRecipients.length > 0 && paperRecipients.map((recipient, index) => (
                                <div key={index} className="recipient-item">
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`name-${index}`} className="form-label font_black_12 font_700">
                                        Contact {index + 1} Name
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <div className="name_wrapper">
                                        <Input
                                          className="form-control form_input font_black_12"
                                          id={`name-${index}`}
                                          name={`name-${index}`}
                                          placeholder="Name"
                                          type="text"
                                          value={recipient.name}
                                          maxLength={30}
                                          readOnly={true}
                                          disabled={true}
                                        />

                                      </div>

                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`address-${index}`} className="form-label font_black_12 font_700">
                                        Address
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`address-${index}`}
                                        name={`address-${index}`}
                                        placeholder="Address"
                                        type="text"
                                        value={recipient.address}
                                        maxLength={30}
                                        readOnly={true}
                                        disabled={true}
                                      />

                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`city-${index}`} className="form-label font_black_12 font_700">
                                        City
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`city-${index}`}
                                        name={`city-${index}`}
                                        placeholder="City"
                                        type="text"
                                        value={recipient.city}
                                        readOnly={true}
                                        disabled={true}
                                        maxLength={30}
                                      />

                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`country-${index}`} className="form-label font_black_12 font_700">
                                        Country
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <InputGroup className="form-control paper_country form_input font_black_12 p-0">

                                        <Select
                                          className="down_arw w-100"
                                          classNamePrefix="react-select"
                                          options={countryPaperOptions}
                                          value={countryPaperOptions.find((option) => option?.value === recipient?.country)}
                                          placeholder="Country"
                                          readOnly={true}
                                          isDisabled={true}
                                          isSearchable={false}
                                           />
                                      </InputGroup>

                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`zipcode-${index}`} className="form-label font_black_12 font_700">
                                        Zip Code
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`zipcode-${index}`}
                                        name={`zipcode-${index}`}
                                        placeholder="ZIP Code"
                                        type="text"
                                        value={recipient.zipcode}
                                        maxLength={30}
                                        readOnly={true}
                                        disabled={true}
                                      />

                                    </Col>
                                  </FormGroup>

                                </div>
                              ))}
                            </div>
                            <FormGroup className=" mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="scheduleDate" className="form-label font_black_12 font_700">
                                  Scheduled Date
                                </label>
                              </Col>
                              <Col sm="8" className="schedule_datepicker">
                                <DatePicker
                                  className="form-control form_input font_black_12"
                                  dateFormat="MM/dd" // Show only month and day
                                  showMonthDropdown
                                  selected={selectedDate}
                                  value={selectedDate}
                                  minDate={today} // Prevent selecting dates before today
                                  locale="en-GB" // Use the English locale (you can use others)
                                  placeholderText="MM/DD"
                                  openToDate={today}
                                  readOnly={true}
                                  disabled={true}
                                />
                                <img src="/images/datepicker_icon.svg" className="datepicker_icon" />
                              </Col>
                            </FormGroup>

                            <FormGroup className="mt-2 mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="repeat" className="form-label font_black_12 font_700">
                                  Repeat
                                </label>
                              </Col>
                              <Col sm="8">
                                <div className="down_arw">
                                  <Input
                                    type="select"
                                    id="repeat"
                                    name="repeat"
                                    className="form-control form_input font_black_12"
                                    value={repeat || "1"}
                                    readOnly={true}
                                    disabled={true}
                                  >
                                    <option value="0">No Repeat</option>
                                    <option value="1">Every 1 Year</option>
                                    <option value="2">Every 2 Year</option>
                                    <option value="3">Every 3 Year</option>
                                    <option value="4">Every 4 Year</option>
                                    <option value="5">Every 5 Year</option>
                                  </Input>
                                </div>
                              </Col>
                            </FormGroup>

                          </li>

                          <li className="del_textarea_wrapper bg-white-theme">
                            {/* <Col className="col-sm-12 col-lg-6 col-xl-4 col-xxl-4"> */}
                            <FormGroup className="msg_area">
                              <label htmlFor="yourMessage" className="form-label font_18 font_700 mb-3">
                                Text And Media
                              </label>
                              <div className="d-flex gap-2 position-relative ">
                                <Input
                                  className="form-control form_input font_black_12 h-auto"
                                  id="yourMessage"
                                  name="yourMessage"
                                  placeholder="Write here..."
                                  type="textarea"
                                  rows="18"
                                  value={yourMessage}
                                  maxLength="1000"
                                  readOnly={true}
                                  disabled={true}
                                />

                                <span class="textarea_count">{yourMessage.length}/1000</span>
                              </div>


                            </FormGroup>


                            <div className="d-flex justify-content-end align-items-center">
                              <FormGroup className="mb-3 mt-1 align-items-center justify-content-end template_buttons">
                                <Button className="btn smoke_btn font_700" onClick={() => cancelSMS()} type="button">
                                  Cancel
                                </Button>
                              </FormGroup>
                            </div>

                          </li>
                        </ul>) : (
                        <ul className={`sms_content_wrapper ${viewTemplate}`}>
                          <li className="delivery_info_wrapper bg-white-theme">
                            <h3 className="delivery_heading font_18 font_700 mb-3">Delivery Information</h3>
                            <div className="add_more_paper">
                              {paperRecipients && paperRecipients.length > 0 && paperRecipients.map((recipient, index) => (
                                <div key={index} className="recipient-item">
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`name-${index}`} className="form-label font_black_12 font_700">
                                        Contact {index + 1} Name
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <div className="name_wrapper">
                                        <Input
                                          className="form-control form_input font_black_12"
                                          id={`name-${index}`}
                                          name={`name-${index}`}
                                          placeholder="Name"
                                          type="text"
                                          value={recipient.name}
                                          onChange={(e) => handlePaperFieldChange(index, 'name', e.target.value)} // Update state on change
                                          maxLength={30}
                                        />
                                        <div className="delete_name_wrapper" onClick={() => removePaperRecipient(index)}>
                                          <img src="/images/delete_icon.svg" alt="Delete" className="delete_icon" />
                                        </div>
                                      </div>
                                      {errors.name && (
                                        <div className="errorVal ">
                                          {errors.name}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`address-${index}`} className="form-label font_black_12 font_700">
                                        Address
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`address-${index}`}
                                        name={`address-${index}`}
                                        placeholder="Address"
                                        type="text"
                                        value={recipient.address}
                                        onChange={(e) => handlePaperFieldChange(index, 'address', e.target.value)} // Update state on change
                                        maxLength={30}
                                      />
                                      {errors.address && (
                                        <div className="errorVal ">
                                          {errors.address}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`city-${index}`} className="form-label font_black_12 font_700">
                                        City
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`city-${index}`}
                                        name={`city-${index}`}
                                        placeholder="City"
                                        type="text"
                                        value={recipient.city}
                                        onChange={(e) => handlePaperFieldChange(index, 'city', e.target.value)} // Update state on change
                                        maxLength={30}
                                      />
                                      {errors.city && (
                                        <div className="errorVal ">
                                          {errors.city}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`country-${index}`} className="form-label font_black_12 font_700">
                                        Country
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <InputGroup className="form-control paper_country form_input font_black_12">

                                        <Select
                                          className="down_arw"
                                          classNamePrefix="react-select"
                                          options={countryPaperOptions}
                                          value={countryPaperOptions.find((option) => option?.value === recipient?.country)}
                                          onChange={(selectedOption) => handlePaperFieldChange(index, "country", selectedOption.value)}
                                          placeholder="Country"
                                          isSearchable={false} />
                                      </InputGroup>
                                      {errors.country && (
                                        <div className="errorVal ">
                                          {errors.country}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  <FormGroup className="mb-3 row align-items-center">
                                    <Col sm="4" className="pe-0">
                                      <label htmlFor={`zipcode-${index}`} className="form-label font_black_12 font_700">
                                        Zip Code
                                      </label>
                                    </Col>
                                    <Col sm="8">
                                      <Input
                                        className="form-control form_input font_black_12"
                                        id={`zipcode-${index}`}
                                        name={`zipcode-${index}`}
                                        placeholder="ZIP Code"
                                        type="text"
                                        value={recipient.zipcode}
                                        onChange={(e) => handlePaperFieldChange(index, 'zipcode', e.target.value)} // Update state on change
                                        maxLength={30}
                                      />
                                      {errors.zipcode && (
                                        <div className="errorVal ">
                                          {errors.zipcode}
                                        </div>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  {/* {paperRecipients.length > 1 && (
                                  <>
                                    <div className="row align-items-center mb-3">
                                      <Col sm="4" className="pe-0"></Col>
                                      <Col sm="8">
                                        <Button
                                          color="danger"
                                          onClick={() => removePaperRecipient(index)}
                                          style={{ marginTop: "10px", marginBottom: "10px" }}
                                          className="btn danger-btn w-100 font_white_14"
                                        >
                                          Remove Recipient
                                        </Button>
                                      </Col>
                                    </div>
                                  </>
                                )} */}
                                </div>
                              ))}

                              {/* Add Another Recipient Button */}
                              <div className="row align-items-center mb-3">
                                <Col sm="4" className="pe-0"></Col>
                                <Col sm="8">
                                  <Button
                                    className="btn primary-btn w-100 font_white_14 font_700"
                                    style={{ marginTop: "10px" }}
                                    onClick={addPaperMore}
                                  >
                                    Add Another Recipient
                                  </Button>
                                </Col>
                              </div>
                            </div>
                            <FormGroup className=" mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="scheduleDate" className="form-label font_black_12 font_700">
                                  Scheduled Date
                                </label>
                              </Col>
                              <Col sm="8" className="schedule_datepicker">
                                <DatePicker
                                  className="form-control form_input font_black_12"
                                  onChange={handleDateChange}
                                  dateFormat="MM/dd" // Show only month and day
                                  showMonthDropdown
                                  selected={selectedDate}
                                  value={selectedDate}
                                  minDate={today} // Prevent selecting dates before today
                                  locale="en-GB" // Use the English locale (you can use others)
                                  placeholderText="MM/DD"
                                  openToDate={today} // Force the calendar to open to today's date
                                />

                                {errors.scheduleDate && (
                                  <div className="errorVal ">
                                    {errors.scheduleDate}
                                  </div>
                                )}
                                <img src="/images/datepicker_icon.svg" className="datepicker_icon" />
                              </Col>
                            </FormGroup>

                            <FormGroup className="mt-2 mb-3 row align-items-center">
                              <Col sm="4" className="pe-0">
                                <label htmlFor="repeat" className="form-label font_black_12 font_700">
                                  Repeat
                                </label>
                              </Col>
                              <Col sm="8">
                                <div className="down_arw">
                                  <Input
                                    type="select"
                                    id="repeat"
                                    name="repeat"
                                    className="form-control form_input font_black_12"
                                    value={repeat || "1"}
                                    onChange={(e) => setRepeat(e.target.value)}
                                  >
                                    <option value="0">No Repeat</option>
                                    <option value="1">Every 1 Year</option>
                                    <option value="2">Every 2 Year</option>
                                    <option value="3">Every 3 Year</option>
                                    <option value="4">Every 4 Year</option>
                                    <option value="5">Every 5 Year</option>
                                  </Input>
                                </div>

                                {errors.repeat && (
                                  <div className="errorVal mt-2">
                                    {errors.repeat}
                                  </div>
                                )}
                              </Col>

                            </FormGroup>

                          </li>

                          <li className="del_textarea_wrapper bg-white-theme">
                            {/* <Col className="col-sm-12 col-lg-6 col-xl-4 col-xxl-4"> */}
                            <FormGroup className="msg_area">
                              <label htmlFor="yourMessage" className="form-label font_18 font_700 mb-3">
                                Text And Media
                              </label>
                              <div className="d-flex gap-2 position-relative ">
                                <Input
                                  className="form-control form_input font_black_12 h-auto"
                                  id="yourMessage"
                                  name="yourMessage"
                                  placeholder="Write here..."
                                  type="textarea"
                                  rows="18"
                                  value={yourMessage}
                                  maxLength="1000"
                                  onChange={(e) => setYourMessage(e.target.value)}
                                />

                                <span class="textarea_count">{yourMessage.length}/1000</span>
                              </div>
                              {errors.yourMessage && (
                                <div className="errorVal mt-2">
                                  {errors.yourMessage}
                                </div>
                              )}

                              <Button className="btn primary-btn w-auto mb-3 mt-3" onClick={() => openTemplate()}>
                                <img src="/images/template_icon.svg" className="" />
                                <div className="text-start">
                                  <span className="font_12">Don't know how to write better?</span>
                                  <p className="font_white_14 font_700"> You Can Choose Our Template For Message</p>
                                </div>
                              </Button>

                            </FormGroup>
                            <FormGroup className="mb-3">
                              <div className="pe-0">
                                <label htmlFor="imageUpload" className="form-label font_black_12 font_700">
                                  Add Files From Storage Or Your PC
                                </label>
                              </div>
                              <div className="upload_file_wrapper">
                                {images.length > 0 && (
                                  <div className="image-previews">
                                    {images.map((image, index) => (
                                      <div key={index} className="image-preview position-relative">
                                        <img
                                          src={image.startsWith('data:image') ? image : process.env.REACT_APP_OTHER_API_URL + image}
                                          alt={`Image preview ${index + 1}`}
                                          className="img-fluid"
                                        />
                                        <button
                                          type="button"
                                          className="delete-button"
                                          onClick={() => handleDeleteImage(index, image.startsWith('data:image') ? "" : image, messageId)} // Handle image deletion
                                        >
                                          <img src="/images/delete_icon.svg" alt="Delete" />
                                        </button>
                                      </div>
                                    ))}

                                  </div>
                                )}

                                <div className="files_uploading">
                                  <img src="/images/upload_plus_icon.svg" className="upload_plus" alt="image" />
                                  <Input
                                    type="file"
                                    id="imageUpload"
                                    name="imageUpload"
                                    className="form-control form_input font_black_12"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageChange} // Event handler to handle multiple image change
                                  />
                                </div>
                              </div>
                            </FormGroup>


                            <div className="d-flex justify-content-end align-items-center">
                              <FormGroup className="mb-3 mt-1 align-items-center justify-content-end template_buttons">
                                <Button className="btn smoke_btn font_700" onClick={() => cancelSMS()} type="button">
                                  Cancel
                                </Button>

                                {load ? (
                                  <Button className="btn smoke_btn  font_700" disabled>
                                    Processing...
                                  </Button>
                                ) : (
                                  <Button className="btn navy_btn font_700" type="submit">
                                    Add A New Schedule
                                  </Button>
                                )}

                              </FormGroup>
                            </div>

                          </li>

                          <li className="template_wrapper">
                            {isVisible && (
                              <>
                                <Row
                                  className="modal-header-hd justify-content-between mb-4"
                                >
                                  <Col
                                    xs="4" className="font_18 font_700">
                                    Text Templates
                                  </Col>
                                  <Col
                                    xs="4"
                                    className="d-flex align-items-center justify-content-end"
                                  >
                                    <div className="select-filter-wrapper search-wrapper mx-0 w-100 h-38 mb-0">
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                      <Input
                                        className="search_message h-38 font_12"
                                        placeholder="Search by keywords"
                                        value={keyword}
                                        type="text"
                                        onChange={(e) => setKeyword(e.target.value)}
                                      />
                                    </div>
                                  </Col>

                                  <Col
                                    xs="4" className="text-end">
                                    <Button className="btn primary-btn h-38 font_white_14" onClick={() => closeTemplate()}>
                                      Close Templates
                                    </Button>
                                  </Col>
                                </Row>

                                <ul className="template_multibtn pb-2">
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "New Year" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "New Year" ? "" : "New Year")}
                                    >
                                      New Year
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Birthday" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Birthday" ? "" : "Birthday")}
                                    >
                                      Birthday
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Fathers day" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Fathers day" ? "" : "Fathers day")}
                                    >
                                      Fathers day
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Mothers day" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Mothers day" ? "" : "Mothers day")}
                                    >
                                      Mothers day
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      className={`btn multiselect-btn font_700 font_black_12 ${tags === "Anniversary" ? "navy_btn" : ""}`}
                                      onClick={() => setTags(tags === "Anniversary" ? "" : "Anniversary")}
                                    >
                                      Anniversary
                                    </button>
                                  </li>
                                </ul>

                                <Row className="my-3 template_data_wrapper">
                                  {templateData.length === 0 ? (
                                    <div className="col-12 text-center">
                                      <p>No templates found.</p>
                                    </div>
                                  ) : (
                                    templateData.map((template, index) => {
                                      return (
                                        <Col
                                          sm="6"
                                          className={`mb-3 ${selectedTemplateId === template.id ? 'card_active' : ''}`}
                                          key={template.id}
                                          onClick={() => handleTemplateClick(template.id)}
                                          style={{ cursor: 'pointer' }} // Added cursor pointer style here
                                        >
                                          <p className="font_black_12 template_data">
                                            {template.text}
                                          </p>
                                        </Col>
                                      );
                                    })
                                  )}
                                </Row>

                                <FormGroup className="mb-3 align-items-center justify-content-end template_buttons">
                                  <Button className="btn smoke_btn font_700" onClick={() => cancelSMS()} type="button">
                                    Cancel
                                  </Button>

                                  {load ? (
                                    <Button className="btn smoke_btn  font_700" disabled>
                                      Processing...
                                    </Button>
                                  ) : (
                                    <Button className="btn navy_btn font_700" type="submit">
                                      Add A New Schedule
                                    </Button>
                                  )}

                                </FormGroup>
                              </>
                            )}
                          </li>
                        </ul>
                      )
                      }

                    </Form>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                tab coneten 5
              </TabPane>

            </TabContent>
          </div>
        </div>
      </div>

      <DeleteModal
        showdt={showDt}
        handleCloseDt={() => setShowDt(false)}
        handledelete={handleDelete}
      />
      <GroupDataModal
        show={showGroupData}
        handleClose={() => setShowGroupData(false)}
        groupData={groupData}
      />
      <ToastContainer />

    </AuthLayout>
  );
};

export default Message;
