import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/login_main-img.svg";
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIServicenew from "../utils/APIGeneralService";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Col
} from "reactstrap";
import PublicLayout from '../components/PublicLayout/publicLayout';


const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);  // New state for toggling password visibility
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const { setAccessToken,accessToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const headers = { "auth-token": authToken };
  useEffect(()=>{
    if(accessToken){
       navigate("/user/message");
    }
  })
  // common validation function

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "The Field Is Not Filled";
    }
    if (!password) {
      errors.password = "The Field Is Not Filled";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters.";
    } else if (password.length > 20) {
      errors.password = "Password must not exceed 20 characters.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //submit login details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    //call common validation function
    if (validate()) {
      //call login API
      const res = await APIServices.post(
        "login",
        {
          email,
          password,
        },
        headers
      );
      if (res?.data?.status === true) {
        setLoad(false);
        let accessToken = res?.data?.detail?.token;
        //update state for login
        setCookies("accessToken", accessToken);
        setCookies("name", res.data?.detail?.name);
        setAccessToken(accessToken);
      
        showToast(res?.data?.message || "Success", "success");
        setTimeout(() => {
          navigate("/user/message");
        }, 1000);
      } else {
        showToast(res?.data?.message || "Something went wrong.", "error");
        setLoad(false);
      }
    }
    setLoad(false);
  };

  //handle email changes
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  //handle password changes
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  //password hide show function
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggles password visibility
  };

  //show success or failed message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <PublicLayout>
      <section className="login_page_inner oborna_login_sec">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="login-form">
                <div className="text-center top">
                  <img src="/images/oborna.svg" className="img-fluid mb-5 logo-login" alt="logo"/>
                  <h1 className="login-heading text-center font_24 font_700 mb-3">Welcome To Oborna!</h1>
                  <p className="login-subheading text-center font_12 font_700 mb-3">
                    Keep in Touch: Send Messages to Family, <br/>Friends, and More on Your Schedule
                  </p>
                </div>
                
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mt-3 align-items-start">
                    <label htmlFor="email" className="font_12 font_700 mt-2">Login</label>
                    <div className="input_box position-relative">
                      <Input
                        className="form-control form_input font_black_16 mb-3"
                        id="email"
                        name="email"
                        placeholder=" Login Id"
                        type="text"
                        onChange={handleEmailChange}
                      />
                      {errors.email && (
                        <div className="errorVal">{errors.email}</div>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password" className="font_12 font_700">Password</label>
                    <div className="input_box position-relative">
                    <div className="position-relative">
                      <Input
                        className="form-control form_input font_black_16 password-input"
                        placeholder="Passcode"
                        type={showPassword ? "text" : "password"} // Toggle between text and password
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        maxLength={20}
                        onChange={handlePasswordChange}
                      />
                      <span
                        className="hide-text font_12"
                        onClick={togglePasswordVisibility} // Toggle password visibility on click
                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                      >
                        {/* {showPassword ? "Hide" : "Show"} Toggle button text */}
                        <img src="/images/eye.svg" className="img-fluid eye_icon" alt="logo"/>
                      </span>
                    </div>
                    {errors.password && (
                      <div className="errorVal">{errors.password}</div>
                    )}
                    </div>
                  </FormGroup>
                  <div className="reset-pass text-end mt-2">
                    <Link className="font_12 font_700" to="/recovery-password">
                     Forgot Password?
                    </Link>
                  </div>

                  <div className="text-center">
                    {load ? (
                      <Button className="btn primary-btn font_black_14 w-100 py-2 my-4 font_700">
                        Processing...
                      </Button>
                    ) : (
                      <Button className="btn primary-btn font_black_14 w-100 py-2 my-4 font_700" type="submit">
                        Sign in
                      </Button>
                    )}
                  </div>
                </Form>
                <div className="text-center bottom">
                <Link className="font_12 font_500 mb-2 d-block" to="">Having trouble logging in? </Link>
                <p className="font_12 font_600">Read the <Link className="font_12 font_700" to="">FAQ</Link> or <Link className="font_12 font_700" to="">Сontact Support</Link></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="login-right-image grey_box row_col_full">
                 <img src="/images/login_sideimage.svg"  className="img-fluid login_sideimage" alt="login image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer/>
    </PublicLayout>
  );
};

export default LoginPage;
