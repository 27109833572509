import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components
import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import Login from "../pages/login.js";
import Forgotpassword from "../pages/forgotpassword.js";
import Resetpassword from "../pages/resetpassword.js";
import Recoverypassword from "../pages/recoverypassword.js";
import ValidateOTP from "../pages/ValidateOTP.js";
import ControlQuestion from "../pages/controlQuestion.js";
import ControlQuestionFailed from "../pages/controlQuestionFailed.js";
import ControlQuestionSuccess from "../pages/controlQuestionSuccess.js";
import ForgotPasswordQuestion from "../pages/forgotpasswordQuestion.js";



//this layout user for befor login routes
const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();


  return (
    <>
      <div className="main-content login-wrapper">
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route path="/recovery-password" element={<Recoverypassword />} exact />
          <Route path="/forgot-password" element={<Forgotpassword />} exact />
          <Route path="/reset-password" element={<Resetpassword />} exact />
          <Route path="/validate-OTP" element={<ValidateOTP />} exact />
          <Route path="/control-question" element={<ControlQuestion />} exact />
          <Route path="/control-question-success" element={<ControlQuestionSuccess />} exact />
          <Route path="/control-question-failed" element={<ControlQuestionFailed />} exact />
          <Route path="/forgot-password-question" element={<ForgotPasswordQuestion />} exact />

        </Routes>
      </div>
    </>
  );
};

export default Auth;
