import { React, useContext,useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContextProvider";
import { getCookies, setCookies } from "../../utils/Helper";
import { Navbar, NavItem,NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const AuthHeader = () => {

//auth header user after login
const navigate = useNavigate();  
const location = useLocation(); // Get the current location
  const { isLoggedIn, logout,data } = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);
  let dateStr = data?.last_login ?? ""
  let username = getCookies('name')
  let formattedDate = "";
  if(dateStr){
    let date = new Date(dateStr);
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
    let year = date.getFullYear();
    formattedDate = `${day}/${month}/${year}`;
  }
  
  let heading = "";
  // Determine the heading based on the pathname
  if (location.pathname.includes('message')) {
    heading = 'Customizer';
  } else if (location.pathname.includes('dashboard')) {
    heading = 'Dashboard';
  } else if (location.pathname.includes('storage')) {
    heading = 'Storage';
  } else if (location.pathname.includes('profile')) {
    heading = 'Profile';
  }
  //CALL logout function
  const callLogout = async (e) => {
    logout()
  };
  const callProfile = async (e) => {
    navigate("/user/profile");
  };
 
  return (
    <div className='header'>
      {/* Header */}
      <Navbar  className="bg-white-theme">
      <NavItem>
        <h4 className="mb-0 font_20 font_700">{heading}</h4>
      </NavItem>
      <NavItem className="profile-account">
        <span className="profile_icon"><img src="/images/theme_mode.svg"/></span>
        <div className="">
          <h3 className="mr-2 font_black_16 font_700 user_title">Hi, {username ? username : ""} !</h3>
          <p className="font_12">{formattedDate ? "Last login "+formattedDate : ""}</p>
        </div>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle className="drop-arrow">
            <i className="fa-solid fa-angle-down"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={callProfile}>
            <i class="fa-regular fa-circle-user me-2" ></i> Profile
            </DropdownItem>
            <DropdownItem onClick={callLogout}>
            <i class="fa-solid fa-arrow-right-from-bracket me-2"></i> Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavItem>
    </Navbar>
      </div>
  );
};

export default AuthHeader;
