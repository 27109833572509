import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Form } from "reactstrap";
import PublicLayout from "../components/PublicLayout/publicLayout";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    // Start the countdown timer
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Redirect when countdown reaches 0
    const timer = setTimeout(() => {
      navigate("/reset-password");
    }, 3000);

    // Cleanup timers on component unmount
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <PublicLayout>
      <section className="login_page_inner oborna_login_sec">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <Form role="form">
                <div className="login-form psw-recovery">
                  <div className="text-center top">
                    <img
                      src="/images/oborna.svg"
                      className="img-fluid mb-5"
                      alt="logo"
                    />
                    <img
                      src="/images/success_icon.svg"
                      className="img-fluid incorrect_icon"
                      alt="incorrect"
                    />
                    <h1 className="login-heading text-center font_24 font_700">
                      Success
                    </h1>
                    <p className="login-subheading text-center font_12 font_400 mb-4 lh-21">
                      Please wait, the system will automatically <br /> redirect
                      you to the password change page <br /> in{" "}
                      <span className="font_700">{countdown}</span> seconds.
                      <br /> If this does not happen, click the button
                    </p>
                  </div>
                  <div className="text-center d-flex align-items-center otp_btn">
                    <Link
                      className="font_12 font_700 d-block w-100"
                      to="/reset-password"
                    >
                      <Button
                        className="btn sent_btn font_black_14 w-100 py-2 font_700"
                        type="button"
                      >
                        Change Password
                      </Button>
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-12 col-md-6">
              <div className="login-right-image grey_box row_col_full">
                <img
                  src="/images/login_sideimage.svg"
                  className="img-fluid login_sideimage"
                  alt="login image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default ForgetPassword;
